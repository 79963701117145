export default {
  proxy_path: "https://mdw2n8twz5.execute-api.eu-west-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://t40i9udhmk.execute-api.eu-west-1.amazonaws.com/api",
  google_api_key: "AIzaSyD84bsWJslpBGrsiinmyTx5aLPpbXFz0a8",
  main_company_term_id: "6",
  crm_base_url: "prod.europe.forwoodsafety.com",
  forwood_id_url: "https://id.europe.forwoodsafety.com?redirect_uri=https://mapreport.europe.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.europe.forwoodsafety.com",
  Auth: {
    userPoolId: "eu-west-1_OFCFdW11F",
    userPoolWebClientId: "2f5e0qnr8speal0jl2oo9rcc9l",
    cookieStorage: {
      domain: ".europe.forwoodsafety.com",
      secure: true
    }
  }
};
